<template>
  <!-- <div class="locale-changer"> -->
  <v-menu
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn
        style="min-width: 48px;"
        color="transparent"
        elevation="0"
        v-on="on"
      >
        <v-img
          :src="require(`./assets/${$i18n.locale}.png`)"
          width="26px"
        />
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="`localeChangerMenuItem-${index}`"
        @click="setLocale(item.key)"
      >
        <v-list-item-title>
          <v-avatar
            size="26px"
            tile
          >
            <img
              :src="require(`./assets/${item.key}.png`)"
              alt="Avatar"
            >
          </v-avatar>
          {{ item.title }}
        <!-- <v-list-tile-title
          class="menu-text"
          v-html="item.title"
        /> -->
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

export default {
  name: 'LocaleChanger',
  props: {
    // locales: {
    //   type: Array,
    //   default: ['en']
    // },
    // showFull: Boolean,
    // dropup: Boolean,
    // locLabel: {
    //   type: String,
    //   default: ''
    // }
  },
  data() {
    return {
      items: [
        { key: 'en', title: 'English' },
        { key: 'ko', title: '한국어' }
      ],
      activeLocale: this.$i18n.locale
    }
  },
  computed: {
    // dropdownLbl: function() {
    //   return this.locLabel ? this.locLabel : this.activeLocale
    // }
  },
  created() {
    this.$i18n.locale = this.$cookies.get('locale') || 'ko'
    this.$bus.$emit('currentLocale', this.$i18n.locale)
  },
  methods: {
    isSelectableLocale(locale) {
      let b = false
      // eslint-disable-next-line array-callback-return
      this.$i18n.availableLocales.find((e) => {
        if (e === locale) {
          b = true
        }
      })
      return b
    },
    setLocale(locale) {
      if (!this.isSelectableLocale(locale)) {
        return false
      }

      this.$cookies.set('locale', locale)
      this.$i18n.locale = locale
      this.$bus.$emit('currentLocale', this.$i18n.locale)
      return null
    }
  }
}
</script>

<style scoped>
.menu-text {
  padding-left: 18px;
  font-size: 0.8em;
}
</style>
